import React, { Component } from 'react';
import ScrollToTop from 'react-scroll-up'

import { Gallery } from '../gallery';
import ArtsService from '../../services/arts.service';
import { Translation } from 'react-i18next';

import './artsListPage.scss';

export default class ArtsListPage extends Component {

    constructor(props) {
        super(props);
        const artsService = new ArtsService();
        
        this.state = { 
            arts: [],
            filteredArts: [],
            filterState: 'all',
            translateId: artsService.getLangId()
        };
        this.artsService = new ArtsService();
    }

    async componentDidMount() {
        const arts = await this.artsService.getArts();
        this.setState({ filteredArts: arts });
        this.setState({ arts });

        // TODO: need to refactor
        let search = this.props.location.search;
        if (search) {
            search = search.substr(1);
            const searchList = search.split('=');
            this.filterArts(searchList[1]);
        }
    }

    routeChange = (id) => {
        this.props.history.push(`${id}`);
    }

    filterArts = (filterState) => {
        let filteredArts = [];
        switch (filterState) {
            case 'inStock':
                filteredArts = this.state.arts.filter(art => art.saleStatusId === 2 || art.saleStatusId === 3);
                break;
            case 'sold':
                filteredArts = this.state.arts.filter(art => art.saleStatusId === 1);
                break;
            default:
                filteredArts = this.state.arts.filter(art => art.saleStatusId !== 3);
        }

        this.props.history.push({
            pathname: '/arts',
            search: `?filter=${filterState}`
        });
        this.setState({ filterState });
        this.setState({ filteredArts });
    }

    getActiveFilterName(filterName) {
        return filterName === this.state.filterState ? 'is-active' : '';
    }

    render() {
        const orderedArts = this.state.filteredArts.sort( (a, b) => {
            return a.order - b.order;
        });

        return (
            <Translation>
                {
                    t =>
                    <div className="arts">
                        <div className="artsHeader">
                            <div className={`button ${this.getActiveFilterName('all')}`} onClick={() => this.filterArts('all')}>{t('all')}</div>
                            <div className={`button ${this.getActiveFilterName('inStock')}`} onClick={() => this.filterArts('inStock')}>{t('in_stock')}</div>
                            {/* <div className={`button ${this.getActiveFilterName('sold')}`} onClick={() => this.filterArts('sold')}>{t('sold')}</div> */}
                        </div>

                        <Gallery images={orderedArts} translateId={this.state.translateId}/>

                        <ScrollToTop showUnder={160}>
                            <img className="scroll-up-img" src="/triangle.jpg" alt="Up"/>
                        </ScrollToTop>
                    </div>
                }
            </Translation>
        );
    }
}