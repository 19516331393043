import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import i18next from 'i18next';

import './App.scss';

import { Header } from './components/header';
import { MainPage } from './components/main';
import { ArtsListPage } from './components/arts-list';
import { ArtPage } from './components/art';
import { AboutPage } from './components/about';
import { ContactsPage } from './components/contacts';
import ArtsService from './services/arts.service';


function App() {
  const artsService = new ArtsService();
  i18next.changeLanguage(artsService.getLangId());

  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL + '/'}>
        <Header/>
        <Switch>
          <Route exact path="/" component={MainPage}/>
          <Route exact path="/arts" component={ArtsListPage}/>
          <Route exact path="/arts/" component={ArtsListPage}/>
          <Route path="/arts/:id" component={ArtPage}/>
          <Route path="/about" component={AboutPage} />
          <Route path="/contacts" component={ContactsPage}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
