import React from 'react';
import { LazyLoadImage, trackWindowScroll }
    from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-css'

import './gallery.scss';

const Gallery = ({ images, scrollPosition, translateId }) => {
    if (images.length === 0) return (<div></div>);

    const breakpointColumnsObj = {
        default: 8,
        3500: 6,
        2600: 5,
        1824: 4,
        1024: 2,
        480: 1
    };

    const getCurrentTranslate = (translates) => {
        const currentTranslate = translates.find(translate => translate.type === translateId);
        return currentTranslate || translates[0];
    }

    const getMainPhoto = (art) => {
        return art.urlMainPhoto || art?.photos[0].urlMin;
    }
    
    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {images.map(art => {
                const translate = getCurrentTranslate(art.translate);
                const urlMainPhoto = getMainPhoto(art);
                const height = 400;
                return (
                    <Link key={art.id} to={`arts/${art.id}`} className="artWrapper">
                        <LazyLoadImage
                            className="photo"
                            effect="blur"
                            key="art.id"
                            height={height}
                            scrollPosition={scrollPosition}
                            threshold={100}
                            src={urlMainPhoto} 
                        />
                        <div className="artName">{translate.name} {art.size}</div>
                    </Link>
                );
            }
            )}
        </Masonry>
    )
};

export default trackWindowScroll(Gallery);