import React, { useEffect, useState } from 'react';

import './aboutPage.scss';
import ArtsService from '../../services/arts.service';

export default function AboutPage() {
    let [about, setAbout] = useState({});
    let [currentTranslate, setCurrentTranslate] = useState({});
    
    const getAbout = async () => {
        const artsService = new ArtsService();
        about = await artsService.getAbout();
        setAbout(about);
        getCurrentTranslate('en');
    }

    useEffect(() => {
        getAbout();
    }, []);



    const getCurrentTranslate = (lang) => {
        if (about && about.translate) {
            currentTranslate = about.translate.find(translate => translate.type === lang);
            currentTranslate = currentTranslate || about.translate[0];
            setCurrentTranslate(currentTranslate);
        }
    }

    const getPhoto = () => {
        return about && about.photoUrl ? about.photoUrl : '';
    }

    return (
        <div className="aboutPage">
            <img className="photo" src={getPhoto()} alt="photo"/>
            <p className="text">
                { currentTranslate.description }
            </p>
        </div>
    );

}