import React, { Component } from 'react';

import { Translation } from 'react-i18next';

import './contactsPage.scss';

export default class ContactsPage extends Component {
    render() {

        return (
            <Translation>
                {
                    t =>
                    <div className="contactsPage">
                            <div>{t('contact_first_line')}</div>
                            <div>{t('contact_second_line')}</div>
                            <div>{t('contact_third_line')}</div>
                        
                        <div className="links">
                            <div className="link">
                                <a href="https://www.facebook.com/slavik.khomitskyi" target="_blank" rel="noopener noreferrer">Facebook</a>
                            </div>
                            <div className="link">
                                <a href="https://www.instagram.com/khomslava" target="_blank" rel="noopener noreferrer">Instagram</a>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}