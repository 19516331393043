import React from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import './swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y ]);

export default ({photos, pageWidth}) => {
    const getPhotos = (photos) => {
        if (!photos) return [];

        const sortedPhoto = photos.sort((a, b) => {
            return a.order - b.order;
        });

        const urlName = pageWidth && pageWidth > 1023 ? 'url' : 'urlMin';

        return sortedPhoto.map((photo, index) => {
            return (
                <SwiperSlide key={index}>
                    <img className="photo" src={photo[urlName]} alt="slide alt" />
                </SwiperSlide>
            )
        });
    }

    return (
        <Swiper
            navigation
            centeredSlides={true}
            slideToClickedSlide={true}
            grabCursor={true}
            loop={true}
            pagination={{ clickable: true }}
            mousewheel={true}
            keyboard={{enabled: true}}
        >
            {getPhotos(photos)}
        </Swiper>
    );
};