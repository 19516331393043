import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './mainPage.scss';

export default class MainPage extends Component {
    render() {
        return (
            <div className="mainPage">
                <Link to="arts">
                    <div className="logoWrapper">
                            <img
                                className="logoLarge"
                                src="/logo.png"
                                alt="Khomitskie art" />
                            <div className="slogan">art by Viktoria & Vyacheslav Khomitskyis</div>
                    </div>
                </Link>
            </div>
        );
    }
}