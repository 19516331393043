import React, { useEffect, useState } from 'react';

import './artPage.scss';
import ArtsService from '../../services/arts.service';
import ImagesSwiper from '../swiper/swiper.js';
import { useTranslation } from "react-i18next";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function ArtPage({ match }) {
    let [art, setArt] = useState({});
    let [currentTranslate, setCurrentTranslate] = useState({});
    let [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const { t } = useTranslation();

    const getArt = async (id) => {
        const artsService = new ArtsService();
        art = await artsService.getArt(id);
        setArt(art);
        const langId = artsService.getLangId();
        getCurrentTranslate(langId);
    }

    useEffect(() => {
        if (match.params && match.params.id) {
            getArt(match.params.id);
        }

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getCurrentTranslate = (lang) => {
        currentTranslate = art.translate.find(translate => translate.type === lang);
        currentTranslate = currentTranslate || art.translate[0];
        setCurrentTranslate(currentTranslate);
    }

    const getStatus = (statusId) => {
        return statusId === 2 ? t('in_stock') : t('sold')
    }

    const getPrice = (price, statusId) => {
        return statusId === 2 ? `${t('price')}: ${price}$` : '';
    }

    return (
        <div className="artPage">
            <div className="descriptionWrapper">
                <div className="shortDescription">
                    <div className="artName">{currentTranslate.name}</div>
                    <div>{t('size')}: {art.size}</div>
                    <div>{t('year')}: {art.year}</div>
                    <div>{t('materials')}: {currentTranslate.materials} </div>
                    <div>{getPrice(art.price, art.saleStatusId)}</div>
                    <div>{getStatus(art.saleStatusId)}</div>
                </div>
                <div className="textDescription">
                    {currentTranslate.description} 
                </div>
            </div>

            <div className="photos">
                {art.photos ? <ImagesSwiper photos={art.photos} pageWidth={windowDimensions.width}/> : ""}
            </div>

            <div className="emptyBlock">
            </div>
        </div>
    )
}