import React, { Component} from 'react';
import { NavLink } from 'react-router-dom';

import { Translation } from 'react-i18next';

import './header.scss';


export default class Header extends Component {

    render() {
        return (
            <Translation>
                {
                    t =>
                    <div className="header">
                        <div className="logo">
                            <NavLink to="/">
                                <img
                                    className="logo"
                                    src="/logo.png"
                                    alt="Khomitskie art" />
                            </NavLink>
                        </div>

                        <ul className="nav-menu">
                            <li>
                                <NavLink to="/arts" activeClassName='is-active'>{t('paintings')}</NavLink>
                            </li>
                            <li>
                                <NavLink to="/about" activeClassName='is-active'>{t('about')}</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contacts" activeClassName='is-active'>{t('contacts')}</NavLink>
                            </li>
                        </ul>
                    </div>
                }
            </Translation>
        );
    }
}
