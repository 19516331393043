export default class ArtsService {
    _apiBase = 'https://arts-53d9d.firebaseio.com';
    _langId;

    getLangId() {
        if (!this._langId) {
            const lang = (navigator.userLanguage || navigator.language).slice(0, 2);
            this._langId = ['en' ,'ru', 'uk'].find(ln => ln === lang) || 'en'
            this._langId = 'ru' === this._langId ? 'uk' : this._langId;
        }

        return this._langId;
    }

    getResource = async (url) => {
        const res = await fetch(`${this._apiBase}${url}`);
        return await res.json();
    };

    getImagesForMain = async () => {
        const images = await this.getResource('/images');
        return  this._transformMainImages(images);
    }

    getArts = async () => {
        const arts = await this.getResource('/arts.json');
        return this._transformArts(arts);
    }

    getArt = async (id) => {
        return await this.getResource(`/arts/${id}.json`);
    }

    getAbout = async () => {
        return await this.getResource(`/about.json`);
    }

    _transformMainImages = (images) => {
        return {
            ...images
        }
    }

    _transformArts = (arts) => {
        return Object.values(arts);
    }

}